<template>
  <div id="app">
    <loading-animation />
    <error-handler />

    <div v-if="updateExists" class="pwa-update" @click="refreshApp">
      Neue Version verfügbar! <i class="fa fa-sync" />
    </div>

    <router-view />
  </div>
</template>

<script>
import LoadingAnimation from "@/components/utility/LoadingAnimation.vue";
import ErrorHandler from "@/components/utility/ErrorHandler.vue";

export default {
  name: "App",
  components: {
    ErrorHandler,
    LoadingAnimation
  },
  data() {
    return {
      refreshing: false,
      registration: null,
      updateExists: false
    };
  },
  created() {
    document.addEventListener("swUpdated", this.showRefreshUI, { once: true });

    const html = document.documentElement; // returns the html tag
    html.setAttribute("lang", "de");
    html.setAttribute("translate", "no");

    console.log("sw>", navigator.serviceWorker);
    navigator.serviceWorker.addEventListener("controllerchange", () => {
      if (this.refreshing) {
        return;
      }
      this.refreshing = true;
      window.location.reload();
    });
  },
  methods: {
    showRefreshUI(e) {
      this.registration = e.detail;
      this.updateExists = true;
    },
    refreshApp() {
      this.updateExists = false;
      if (!this.registration || !this.registration.waiting) {
        return;
      }
      this.registration.waiting.postMessage("skipWaiting");
    }
  }
};
</script>

<style lang="scss">
@import "@/assets/shared.scss";

body,
html {
  margin: 0;
}

#app {
  width: 100vw;
  min-height: 100vh;
  font-family: "Montserrat", sans-serif;
  font-size: 28px;
  font-weight: 800;
  background-color: $primary;
  color: white;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
}

.pwa-update {
  background-color: $red;
  color: white;
  padding: 10px;
  font-size: 16px;
}
</style>
