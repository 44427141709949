import Vue from "vue";
import VueI18n from "vue-i18n";
import App from "@/App.vue";
import router from "./router";
import store from "./store";
import { logger } from "@/mixins/logging.js";
import { session } from "@/mixins/session.js";

import "@fortawesome/fontawesome-free/css/all.css";

import ImageUploader from "vue-image-upload-resize";
import "./registerServiceWorker";
import messages from "./i18n/messages.json";

Vue.use(VueI18n);
Vue.use(ImageUploader);

import errorHandler from "@/components/utility/ErrorHandler.vue";
Vue.prototype.$error = errorHandler.methods.error;

import { determineLanguageId } from "@/lib/languages.js";

// -----------------
// INTERNATIONALIZATION
// -----------------
export const i18n = new VueI18n({
  locale: determineLanguageId(),
  fallbackLocale: "de-DE",
  messages // set locale messages
});

// ------------------
// LOADING ANIMATION
// ------------------
Vue.mixin({
  methods: {
    $loading(isLoading) {
      this.$store.commit("loading", isLoading);
    }
  }
});

Vue.config.productionTip = false;

Vue.mixin(logger);
Vue.mixin(session);

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount("#app");
