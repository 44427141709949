import axios from "axios";

import ErrorHandler from "@/components/utility/ErrorHandler.vue";

import store from "@/store.js";

import { i18n } from "../main.js";

const isHandlerEnabled = (config = {}) => {
  // FIXME: axios 0.19.0 does not allow for custom config fields.
  // a new release should allow custom or meta fields to be added
  // eslint-disable-next-line no-prototype-builtins
  return config.hasOwnProperty("handlerEnabled") && !config.handlerEnabled
    ? false
    : true;
};

const errorHandler = (err) => {
  store.commit("loading", false);
  if (isHandlerEnabled(err.config)) {
    ErrorHandler.methods.error(err);
  }
  return Promise.reject({ ...err });
};

axios.interceptors.response.use(
  (response) => response,
  (error) => errorHandler(error)
);

// Set language 'accept-language' header
axios.interceptors.request.use((config) => {
  config.headers.post["Accept-Language"] = i18n.locale;
  config.headers.get["Accept-Language"] = i18n.locale;
  config.headers.put["Accept-Language"] = i18n.locale;
  config.headers.patch["Accept-Language"] = i18n.locale;
  config.headers.delete["Accept-Language"] = i18n.locale;
  return config;
});

export default axios;
