<template>
  <div class="new-app-container modal-overlay">
    <div class="modal-content warning-dialog" @click.stop>
      <div class="warning">
        Die Webapp wird nicht weiter unterstützt.
      </div>
      <div class="message">
        <br />
        Bitte die neue App hier herunterladen:
      </div>

      <div class="controls">
        <div class="button primary" @click="appStore">
          iPhone
        </div>
        <div class="button primary" @click="playStore">
          Android
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "NewApp",
  data() {
    return {
      appStoreLink:
        "https://apps.apple.com/us/app/truckoo-inspection/id1595611320",
      playStoreLink:
        "https://play.google.com/store/apps/details?id=com.truckoo.workshop"
    };
  },
  methods: {
    playStore() {
      window.location.href = this.playStoreLink;
    },
    appStore() {
      window.location.href = this.appStoreLink;
    }
  },
  computed: {
    os() {
      let userAgent = navigator.userAgent || navigator.vendor || window.opera;

      if (/windows phone/i.test(userAgent)) {
        return "windows";
      }

      if (/android/i.test(userAgent)) {
        return "android";
      }

      if (/Macintosh|iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
        return "ios";
      }

      return "unknown";
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/shared.scss";

.warning {
  padding: 10px;
  font-size: 24px;
}

.warning-dialog {
  background-color: white;
  width: 80vw;
  color: $darkText;
  font-size: 20px;
  padding: 30px;
  box-sizing: border-box;

  .controls {
    position: relative;
    top: 80px;

    div {
      margin-bottom: 20px;
      border: none;
    }
  }
}
</style>
