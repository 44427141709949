const stage = process.env.VUE_APP_INSTANCE

import http from '@/services/http.js'

const baseURL =
  process.env.VUE_APP_SLACK_NOTIFICATIONS_SERVICE ||
  'https://europe-west1-truckoo-dev.cloudfunctions.net/TruckooSlackNotification'

class SlackService {
  sendAssessmentFinalizedNotification(user, assID) {
    let mrkdwn = []
    mrkdwn.push(`*🆕 New Assessment* [ _${stage}_ ]`)
    mrkdwn.push(`👨🏽‍🔧 ${user}`)
    mrkdwn.push(`${assID}`)
    this.send('new-assessments', mrkdwn)
  }

  sendNewSearchRequestNotification(username, uid, companyName) {
    let mrkdwn = []
    mrkdwn.push(`*🔍 New Search Request* [ _${stage}_ ]`)
    mrkdwn.push(`submitted by ${username} for ${companyName}`)
    mrkdwn.push(`${uid}`)
    this.send('new-assessments', mrkdwn)
  }

  sendAnswerMissingFeedback(username, questionUID, feedback) {
    let mrkdwn = []
    mrkdwn.push(`*🙋 Missing Answer* [ _${stage}_ ]`)
    mrkdwn.push(`${username}: ${questionUID}`)
    mrkdwn.push(`${feedback}`)
    this.send('missing-answers', mrkdwn)
  }

  sendDebugInfo(data) {
    let mrkdwn = []
    mrkdwn.push(JSON.stringify(data))
    this.send('workshop-debug-info', mrkdwn)
  }

  send(channel, message) {
    http.post(
      baseURL,
      {
        channel: channel,
        message: message
      },
      {
        headers: { 'X-Auth-Truckoo': '7qTN+mj]Q}hM*{AxTruckie' }
      }
    )
  }
}

export default new SlackService()
