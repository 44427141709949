<template>
  <div class="abort-container modal-overlay">
    <div class="modal-content abort-dialog" @click.stop>
      <div class="message">
        {{ $t('abort.areYouSure') }}
      </div>
      <div class="controls">
        <div class="button secondary" @click="$emit('close')"> {{ $t('abort.noButton') }}</div>
        <div class="button primary" @click="$emit('abort')"> {{ $t('abort.yesButton') }} </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Abort',
  data() {
    return {
      key: "value"
    }
  },
  methods: {
    name() {

    }
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/shared.scss';

.abort-dialog {
  background-color: white;
  width: 80vw;
  color: $darkText;
  font-size: 20px;
  padding: 30px;
  box-sizing: border-box;

  .controls {
    position: relative;
    top: 80px;

    div {
      margin-bottom: 20px;
      border: none;
    }
  }
}
</style>
