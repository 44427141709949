<template>
  <div v-if="latestError && showErrorMessage" class="errorhandler-container">
    <div class="header">
      {{ $t('errorHandler.header') }}
    </div>
    <div class="error">
      <div v-if="latestError.type == 'simple'">
        {{ latestError.message }}
      </div>
      <div v-else>
        {{ $t('errorHandler.status', { complexErrorMessage }) }} 
      </div>
    </div>
    <div class="action">
      <div class="default-action">
        {{ $t('errorHandler.defaultAction') }} 
      </div>
    </div>
    <div class="dismiss-container">
      <div class="dismiss button" @click="showErrorMessage = false">{{ $t('errorHandler.dismiss') }}</div>
    </div>
  </div>
</template>

<script>
import EventBus from '@/services/bus.js'

// import xray from '@/services/xray-service.js'

export default {
  name: 'ErrorHandler',
  data() {
    return {
      errors: [],
      latestError: null,

      showErrorMessage: false,
    }
  },
  created() {
    EventBus.$on('error', this.errorReceived)
  },
  methods: {
    error(e) { // registered on Vue.prototype.$error
      if(e == null || e == undefined) {
        console.log('undefined error received')
        return
      }
      let error 
      if(typeof e == 'string')
        error = { type: 'simple', message: e }
      else 
        error = { type: 'complex', err: e }
      EventBus.$emit('error', error)
    },
    errorReceived(e) {
      console.dir(e)
      this.latestError = e.err
      this.showErrorMessage = true 

      // xray.logEvent({
      //   eventData: {
      //     type: "error",
      //     payload: e
      //   }
      // })
    }
  },
  computed: {
    complexErrorMessage() {
      let m = ''
      if(this.latestError.message) {
        m = this.latestError.message
      }
      if(this.latestError.statusText) {
        m += ' - ' + this.latestError.statusText
      }
      if(this.latestError.data) {
        m += ' - ' + this.latestError.data
      }
      return m
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/shared.scss';

.errorhandler-container {
  position: fixed;
  top: 30px;
  right: 30px;
  padding: 40px;
  text-align: left;
  border-radius: 6px;
  font-size: 16px;
  @include shadow;

  max-width: 30vw;

  background-color: white;
  color: $red;
  border-left: 5px solid $red;

  z-index: 1001;

  .header {
    color: $darkText;
    margin-bottom: 30px;
  }

}

.action {
  margin: 30px 0px;

  .default-action {
    color: $primary;
  }
}

.dismiss-container {
  text-align: right;

  .dismiss {
    display: inline-block;
    padding: 10px 20px;
    border: 1px solid $primary;
    background-color: $primary;
    color: white;
    cursor: pointer;
    transition: background-color 0.1s;

    &:hover {
      box-shadow: 2px 2px 4px rgba(black, 0.2);
    }
  }
}

@media only screen and (max-width: 600px) {
  .errorhandler-container {
    top: 10px;
    right: 10px;
    max-width: 90vw;
    box-sizing: border-box;
  }
}
</style>
