<template>
  <div class="finalize-view-container">
    <div class="header">
      <div class="left" style="opacity: 0;">
        <i class="fa fa-times"/>
      </div>
      <div class="middle"> {{ $t('finalize.release') }}</div>
      <div 
        class="right" 
        @click="showAbortDialog = true"
      >
        <i class="fa fa-times"/>
      </div>
    </div>
    <div class="content">
      {{ $t('finalize.finishEvaluation') }}
      <div class="controls">
        <div 
          class="button primary" 
          @click="submit()"
        >
          {{ $t('finalize.submitData') }}
        </div>
      </div>
    </div>

    <abort v-if="showAbortDialog" @close="showAbortDialog = false" @abort="abort()"/>
  </div>
</template>

<script>
import Abort from '@/components/utility/Abort.vue'
export default {
  name: 'FinalizeView',
  components: {
    Abort,
  },
  data() {
    return {
      showAbortDialog: false,
    }
  },
  methods: {
    submit() {
      this.$store.dispatch('finalize')
      this.$router.push({ path: '/success'})
    },
    abort() {
      this.$store.commit('resetState')
      this.$router.push('/')
    }
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/shared.scss';

.finalize-view-container {
}

.header {
  @include shadow();
  display: flex;
  color: white;

  div {
    height: 60px;
    line-height: 60px;
    max-height: 60px;
  }

  .left {
    padding-left: 20px;
  }
  .right {
    padding-right: 20px;
  }

  .middle {
    flex: 1;
    font-size: 14px;
  }
}

.content {
  padding: 50px;
  text-align: center;
}

.controls {
  margin: 40px;
  position: absolute;
  bottom: 0;
  left: 0px;
  right: 0px;
}

</style>
