<template>
  <div class="settings-container">
    <div @click="toggleDebug">Debug mode on? {{ debugMode }}</div>
  </div>
</template>

<script>
export default {
  name: "Settings",
  data() {
    return {
      debugMode: false,
    };
  },
  methods: {
    async toggleDebug() {
      this.debugMode = !this.debugMode;
      this.$store.commit("setDebugMode", this.debugMode);
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/shared.scss";
</style>
