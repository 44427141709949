import httpClient from "@/services/http.js";

import jwt_decode from "jwt-decode";

const baseURL = process.env.VUE_APP_AUTH_SERVICE_URL;

const localStorageJwtKey = "[truckoo::jwt]";

class AuthService {
  async login(username, password) {
    const jwt = await httpClient
      .post(baseURL + "/login", {
        uid: username.toLowerCase(),
        password: password
      })
      .then(res => res.data);

    window.localStorage.setItem(localStorageJwtKey, jwt);

    httpClient.defaults.headers.authorization = "Bearer " + jwt;

    let decoded = jwt_decode(jwt);
    return decoded;
  }

  logout() {
    window.localStorage.removeItem(localStorageJwtKey);
  }

  requestPasswordReset(email) {
    return httpClient
      .post(baseURL + "/request-password-reset", { uid: email.toLowerCase() })
      .then(r => r.data);
  }

  saveNewPassword(email, password, token) {
    return httpClient
      .post(baseURL + "/password-reset", {
        uid: email,
        password: password,
        token: token
      })
      .then(r => r.data);
  }

  registerUser(email, password) {
    return httpClient
      .post(baseURL + "/register", {
        uid: email.toLowerCase(),
        password: password
      })
      .then(r => r.data);
  }
}

export default new AuthService();
