const localStorageJwtKey = "[truckoo::jwt]";

import jwt_decode from "jwt-decode";

import httpClient from "@/services/http.js";

const REFRESH_URL = process.env.VUE_APP_AUTH_SERVICE_URL + "/refresh";

let _autoRefresh;

function storeJWT(jwt) {
  window.localStorage.setItem(localStorageJwtKey, jwt);
}

function deleteStoredJWT() {
  window.localStorage.removeItem(localStorageJwtKey);
}

async function initialLogin(jwt) {
  storeJWT(jwt);
  await userIsLoggedIn();
}

async function userIsLoggedIn() {
  let jwt = window.localStorage.getItem(localStorageJwtKey);
  console.log("found jwt");
  if (jwt == null) {
    return;
  }
  if (tokenIsExpired(jwt)) {
    deleteStoredJWT();
    return;
  }
  // token found and not expired
  httpClient.defaults.headers.authorization = "Bearer " + jwt;

  // refresh immediately for new claims, user deactivated etc.
  console.log("refreshing token");
  let refreshedToken = await refreshToken();

  storeJWT(refreshedToken);
  httpClient.defaults.headers.authorization = "Bearer " + refreshedToken;

  let decoded = jwt_decode(refreshedToken);

  if (!_autoRefresh) {
    setupTokenAutoRefresh();
  }
  return decoded;
}

function setupTokenAutoRefresh() {
  _autoRefresh = window.setInterval(async () => {
    const refreshedToken = await refreshToken().catch(err =>
      console.log("error during refresh: ", err)
    );
    if (refreshedToken) {
      httpClient.defaults.headers.authorization = "Bearer " + refreshedToken;
    }
  }, 3600000);
}

function refreshToken() {
  return httpClient
    .get(REFRESH_URL)
    .then(r => r.data)
    .catch(err => handleRefreshError(err));
}

function handleRefreshError(err) {
  if (err.response.status == 403) {
    deleteStoredJWT();
    throw err;
  }
}

function tokenIsExpired(jwt) {
  let decoded = jwt_decode(jwt);
  return decoded.exp < Math.floor(Date.now() / 1000);
}

// validates that the user can provide valid claims for this scope
function verifyScope(jwt, claims) {
  let decoded = jwt_decode(jwt);
  console.dir(decoded);
  let authorized = false;
  claims.forEach(c => {
    if (decoded.claims.includes(c)) {
      console.log("claim matches: ", c);
      authorized = true;
    }
  });

  return authorized;
}

export {
  refreshToken,
  userIsLoggedIn,
  storeJWT,
  verifyScope,
  tokenIsExpired,
  initialLogin
};
