<template>
  <div class="language-selector-container" @click="expanded = !expanded">
    <div class="selected">
      <img :src="require(`../../assets/flags/${getFlag()}`)" />
    </div>
    <div v-if="expanded" class="language-options expanded" @click.stop>
      <div
        v-for="language in allowedLanguages"
        class="language"
        @click.stop="() => updateLanguage({ id: language.id })"
        :key="language.id"
      >
        <img :src="require(`../../assets/flags/${language.flag}`)" />
      </div>
    </div>
  </div>
</template>

<script>
import { ALLOWED_LANGUAGES, LOCALE_STORAGE_KEY } from "@/lib/languages.js";

export default {
  name: "LanguageSelector",
 
  data() {
    return {
      expanded: false,
      allowedLanguages: ALLOWED_LANGUAGES
    };
  },
  methods: {
    updateLanguage(l) {
      localStorage.setItem(LOCALE_STORAGE_KEY, l.id);
      this.$i18n.locale = l.id;
      this.expanded = false;
      this.$emit("language-updated", l);
    },
    getFlag() {
      let index = this.allowedLanguages.findIndex(
        l => l.id === this.$i18n.locale
      );
      let locale = this.allowedLanguages[index];
      return locale.flag;
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/shared.scss";

.language-selector-container {
  position: relative;
}

.selected {
  height: 30px;
  width: 40px;
  cursor: pointer;

  img {
    height: 30px;
    width: 40px;
    border-radius: 4px;
  }

  &:hover {
    transform: scale(1.05);
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
  }
}

.expanded {
  position: absolute;
  top: -10px;
  right: -10px;
  background-color: #fff;
  padding: 10px;
  z-index: 9999;
  max-height: 80vh;
  overflow-y: scroll;
  border-radius: 6px;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);

  .language {
    width: 100%;
    margin-bottom: 5px;
    cursor: pointer;
    transition: transform 0.1s;

    img {
      height: 30px;
      width: 40px;
      border-radius: 4px;
      box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
    }

    &:hover {
      transform: scale(1.05);
    }
  }
  .language:last-child {
    margin-bottom: 0;
  }
}
</style>
