<template>
  <div class="browser-warning-container modal-overlay">
    <div class="modal-content warning-dialog" @click.stop>
      <div class="warning">
        {{ $t("browserWarning.title") }}
      </div>
      <div class="message">
        {{ $t("browserWarning.message", { browser }) }}
      </div>

      <div class="controls">
        <div class="button primary" @click="redirect">
          {{ $t("browserWarning.downloadChrome") }}
        </div>
        <div class="button secondary" @click="$emit('close')">
          {{ $t("browserWarning.tryWithOtherBrowser") }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "BrowserWarning",
  data() {
    return {
      appStoreLinkToChrome:
        "itms-apps://apps.apple.com/us/app/google-chrome/id535886823",
      playStoreLinkToChrome:
        "https://play.app.goo.gl/?link=https://play.google.com/store/apps/details?id=com.android.chrome&hl=en&gl=US"
    };
  },
  props: {
    browserInfo: Object
  },
  methods: {
    redirect() {
      if (this.os == "android") {
        window.location.href = this.playStoreLinkToChrome;
      } else if (this.os == "ios") {
        window.location.href = this.appStoreLinkToChrome;
      } else {
        window.location.href = "https://www.google.com/chrome/";
      }
    }
  },
  computed: {
    os() {
      let userAgent = navigator.userAgent || navigator.vendor || window.opera;

      if (/windows phone/i.test(userAgent)) {
        return "windows";
      }

      if (/android/i.test(userAgent)) {
        return "android";
      }

      if (/Macintosh|iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
        return "ios";
      }

      return "unknown";
    },
    browser() {
      if (this.browserInfo.edge) {
        return "Microsoft Edge als Browser";
      } else if (this.browserInfo.firefox) {
        return "Mozilla Firefox als Browser";
      } else {
        return "einen nicht unterstützten Browser";
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/shared.scss";

.warning {
  padding: 10px;
  font-size: 24px;
}

.warning-dialog {
  background-color: white;
  width: 80vw;
  color: $darkText;
  font-size: 20px;
  padding: 30px;
  box-sizing: border-box;

  .controls {
    position: relative;
    top: 80px;

    div {
      margin-bottom: 20px;
      border: none;
    }
  }
}
</style>
