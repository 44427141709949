<template>
  <div class="additional-information-container">
    <div class="header">
      <div class="left" style="opacity: 0;">
        <i class="fa fa-times" />
      </div>
      <div class="middle">{{ $t("additionalInformation.header") }}</div>
      <div class="right" @click="showAbortDialog = true">
        <i class="fa fa-times" />
      </div>
    </div>
    <div class="content">
      {{ $t("additionalInformation.content") }}
      <div class="message">
        <textarea v-model="message" class="input" rows="5" />
      </div>
      <div class="controls">
        <div class="button primary" @click="submit()">
          {{ buttonLabel }}
        </div>
      </div>
    </div>

    <abort
      v-if="showAbortDialog"
      @close="showAbortDialog = false"
      @abort="abort()"
    />
  </div>
</template>

<script>
import Abort from "@/components/utility/Abort.vue";

export default {
  name: "AdditionalInformation",
  components: {
    Abort
  },
  data() {
    return {
      showAbortDialog: false,
      message: ""
    };
  },
  methods: {
    submit() {
      this.$store.dispatch("addInfo", {
        key: "additional-information",
        value: this.message
      });
      this.$router.push({ path: "/contact-information" });
    },
    abort() {
      this.$store.commit("resetState");
      this.$router.push("/");
    }
  },
  computed: {
    buttonLabel() {
      return this.message.length < 1
        ? this.$t("additionalInformation.no")
        : this.$t("general.continue");
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/shared.scss";

.header {
  @include shadow();
  display: flex;
  color: white;

  div {
    height: 60px;
    line-height: 60px;
    max-height: 60px;
  }

  .left {
    padding-left: 20px;
  }
  .right {
    padding-right: 20px;
  }

  .middle {
    flex: 1;
    font-size: 14px;
  }
}

.content {
  padding: 50px;
  text-align: left;
}

.message {
  padding: 10px 0px;
}

.input {
  background-color: white;
  border-radius: 0;
  color: $primary;
  font-size: 16px;
  resize: none;
}

.controls {
  margin: 40px;
  position: absolute;
  bottom: 0;
  left: 0px;
  right: 0px;
}
</style>
