<template>
  <div class="main">
    
    <header-bar/>

    <div class="back-button" @click="gotoMenu()">
      <i class="fa fa-chevron-left"/>
    </div>

    <div class="header title">
      {{ $t('main.header') }}
    </div>

    <div>
      <router-link
        :to="{ name: 'data-input' }"
        tag="div"
        class="button primary"
      >
        {{ $t('main.startEvaluation') }}
      </router-link>

      <div
        v-if="unfinshedAssessment"
        @click="prepareAssessmentContinuation"
        class="button primary"
        style="margin-top: 5pt"
      >
        {{ $t('main.continueWithLast') }} 
      </div>
    </div>

   <div
      v-if="$isAdmin"
      class="settings"
    >
      <settings />
    </div>

 
    <browser-warning 
      v-if="showWarning" 
      @close="showBrowserWarning = false" 
      :browser-info="problematicBrowser"
    />
  </div>
</template>

<script>
import HeaderBar from '@/components/HeaderBar.vue'

import assessmentService from '@/services/assessment-service.js'
import Settings from '@/components/Settings.vue'
import BrowserWarning from '@/components/utility/BrowserWarning.vue'

export default {
  name: 'Main',
  components: {
    BrowserWarning,
    HeaderBar,
    Settings,
  },
  created() {
    //if (process.env.VUE_APP_INSTANCE=="prod") {
    this.$log(this.$username + " just opened the app: " + navigator.userAgent)
    //}
  },
  async mounted() {
    this.$loading(true)
    this.unfinshedAssessment = await assessmentService.getUnfinishedAssessment()
    this.$loading(false)
  },
  data() {
    return {
      unfinshedAssessment: null,

      showBrowserWarning: null,
    }
  },
  methods: {
    prepareAssessmentContinuation(){
      this.$store.commit('resumeAssessment', this.unfinshedAssessment)
      this.$router.push({path: 'data-input'})
    },
    gotoMenu() {
      this.$router.push('/menu').catch(() => {})
    }
  },
  computed: {
    showWarning() {
      if (this.showBrowserWarning == null)
        return this.problematicBrowser.problematic
      return this.showBrowserWarning
    },
    // FIXME extract to autonomous js-file
    problematicBrowser() {
      let ua = navigator.userAgent.toLowerCase()
      return {
        problematic: ua.includes("edg") || ua.includes("firefox"),
        firefox: ua.includes("firefox") || ua.includes("FxiOS"),
        edge: ua.includes("edg"),
        safari: ua.includes("safari")
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/shared.scss';

.main {
  padding: 50px 20px;
  position: relative;
  min-height: calc(100vh - 80px);
}

.back-button {
  position: absolute;
  left: 10px;
  top: 10px;
  padding: 10px;
  z-index: 100;
}

.logo {
  width: 300px;
  margin: auto;
  max-width: 100vw;
}

.header {
  padding: 50px 0px;
}

.loading {
  padding: 20px;
}
</style>
