<template>
  <div class="headerbar-container">
    <div class="logo" @click="gotoHome()">
      <img src="../assets/truckoo-logo-white.svg" />
    </div>

    <div v-if="displayLanguageSelector" class="languageSelector">
      <language-selector />
    </div>
  </div>
</template>

<script>
import LanguageSelector from "./utility/LanguageSelector.vue";

export default {
  name: "HeaderBar",
  components: {
    LanguageSelector
  },
  props: {
    displayLanguageSelector: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      key: "value"
    };
  },
  methods: {
    gotoHome() {
      this.$router.push("/menu").catch(() => {});
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/shared.scss";

.headerbar-container {
  height: 80px;
  box-sizing: border-box;
  background-color: $dark;
  color: $primary;
  text-align: center;
  box-shadow: 2px 2px 10px rgba(black, 0.2);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
}

.logo {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 30%;

  img {
    max-width: 100%;
    max-height: 100%;
  }
}

.languageSelector {
  right: 30px;
  top: 10px;
  border-radius: 4px;
  position: absolute;

  //@include media($mobile) {
  //  margin-left: 15px;
  //}
}
</style>
