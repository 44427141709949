import Vue from "vue";
import Router from "vue-router";

import { userIsLoggedIn } from "@/utils/auth.js";

import LoginView from "@/views/LoginView.vue";
import Main from "@/views/Main.vue";
import Menu from "@/views/Menu.vue";
import ContactInformation from "@/views/ContactInformation.vue";
import AdditionalInformation from "@/views/AdditionalInformation.vue";
import FinalizeView from "@/views/FinalizeView.vue";
import Success from "@/views/Success.vue";

import SearchRequest from "@/views/search_request/SearchRequest.vue";

import store from "@/store";

Vue.use(Router);

const router = new Router({
  mode: "history",
  base: process.env.BASE_URL,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  },
  routes: [
    {
      path: "/",
      name: "menu",
      component: Menu
    },
    {
      path: "/main",
      name: "main",
      component: Main
    },

    {
      path: "/menu",
      name: "menu",
      component: Menu
    },
    {
      path: "/data-input",
      name: "data-input",
      component: () => import("@/views/LookAheadFlow.vue")
    },
    {
      path: "/enter-search-request",
      name: "enter-search-request",
      component: () => import("@/views/search_request/SearchRequestInput.vue")
    },

    {
      path: "/contact-information",
      name: "contact-information",
      component: ContactInformation
    },
    {
      path: "/additional-information",
      name: "additional-information",
      component: AdditionalInformation
    },
    {
      path: "/finalize",
      name: "finalize",
      component: FinalizeView
    },
    {
      path: "/success",
      name: "success",
      component: Success
    },
    {
      path: "/login",
      name: "login",
      component: LoginView
    },
    {
      path: "/search-request",
      name: "search-request",
      component: SearchRequest
    }
  ]
});

router.beforeEach(async (to, from, next) => {
  let decodedJwt = await userIsLoggedIn();
  if (decodedJwt) {
    store.commit("setDecodedJwt", decodedJwt);
  }

  // FIXME: this is temporary
  if (!store.getters.isNewLoginServiceToken) {
    store.dispatch("logout");
  }

  if (to.name == "login") {
    next();
  }
  if (!store.getters.isAuthenticated) {
    next("/login");
  }
  next();
});

export default router;
