<template>
  <div class="login-view-container">
    <header-bar />

    <div class="title">
      {{ $t('login.signIn') }}
    </div>
    <div class="login-form">
      <label for="username" class="input-label">
        {{ $t('login.userName') }}
      </label>
      <input id="username" type="text" class="input" v-model.trim="username" />
      <label for="password" class="input-label"> {{ $t('login.password') }}</label>
      <input
        id="password"
        type="password"
        class="input"
        v-model.trim="password"
        @keyup.enter="login()"
      />
      <div class="forgot-password">
        <a :href="passwordResetLink" target="_blank">
          {{ $t('login.forgotPassword') }} 
        </a> 
      </div>
    </div>
    <div class="actions">
      <div class="button primary" :disabled="!dataValid" @click="login()">
        {{ $t('login.login') }}   
      </div>
      <div class="signup">
        <a :href="signupURL" target="_blank">
          {{ $t('login.registerNow') }} 
        </a>
      </div>
    </div>

    <div v-if="showPopup" class="popup">
      <div class="title"> {{ $t('login.ups') }} </div>
      <div class="message">
        {{ $t('login.loginFailed') }}
        <p></p>
        {{ $t('login.updatePassword') }}
      </div>
      <div class="actions">
        <a href="https://truckoo.com/reset-password" target="_blank">
          <div class="reset button primary">{{ $t('login.resetPassword') }}</div>
        </a>
        <div class="close button secondary" @click="showPopup = false">
          {{ $t('login.tryAgain') }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import HeaderBar from '@/components/HeaderBar.vue'

export default {
  name: 'LoginView',
  components: {
    HeaderBar
  },
  data() {
    return {
      username: '',
      password: '',

      error: null,

      showPopup: false
    }
  },
  methods: {
    async login() {
      try {
        await this.$store.dispatch('login', {
          username: this.username,
          password: this.password
        })
      } catch (err) {
        if (err.response.status == 401) {
          this.showPopup = true
        }
      }

      if (this.$store.getters.isAuthenticated) {
        this.$router.push(this.$route.query.redirect || '/menu')
      }
    }
  },
  computed: {
    isAuthenticated() {
      return this.$store.getters.isAuthenticated
    },
    passwordResetLink() {
      return `${process.env.VUE_APP_LOGIN_GATEWAY}/reset-password`
    },
    signupURL() {
      return `${process.env.VUE_APP_OFFER_BASE_URL}/signup`
    },
    dataValid() {
      return this.username != '' && this.password != ''
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/shared.scss';

a {
  text-decoration: none;
  color: inherit;
}

.login-view-container {
  padding: 50px 30px;
  max-width: 100vw;
  padding-top: 100px;
  position: relative;
}

.title {
  margin: 20px 0px;
  text-align: left;
}

.login-form {
  text-align: left;
}

.forgot-password {
  font-size: 16px;
  text-align: right;
  padding: 10px 0px;
}

#username {
  text-transform: lowercase;
}

.actions {
  margin-top: 30px;

  .signup {
    font-size: 16px;
    padding: 10px 0px;
    margin-top: 10px;
  }
}

.popup {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background-color: white;
  color: $dark;
  padding: 50px;
  overflow: scroll;

  .title {
    font-weight: bold;
    font-size: 26px;
    margin: 30px 0px;
  }
  .message {
    font-size: 24px;
    text-align: left;
    font-weight: normal;
  }

  .actions {
    margin-top: 30px;

    .button {
      margin: 20px 0px;
    }
  }
}
</style>
