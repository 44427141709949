/* eslint-disable no-console */
import Vue from 'vue'
import Vuex from 'vuex'

import authService from '@/services/auth-service.js'
import slackService from '@/services/slack-service.js'
import assessmentService from '@/services/assessment-service.js'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    inDebugMode: false,
    user: null,
    jwt: null,

    decodedJwt: null,

    error: null,
    loading: 0,

    assID: '',
    answers: {},
    contact: {},

    temporaryShowNewLoginRequired: false // this is only for transition phase to smoosh
  },
  mutations: {
    initAssID(state) {
      let now = new Date()
      state.assID = now.toISOString() + '-' + state.decodedJwt.sub
    },

    setTruth(state, truth) {
      state.answers = truth
    },

    setContact(state, contact) {
      state.contact = contact
    },

    setAdditionalInfo(state, { key, value }) {
      state.answers[key] = value
    },

    resumeAssessment(state, assessment) {
      state.assID = assessment.assID
      state.answers = assessment.input
    },

    resetState(state) {
      state.assID = ''
      state.answers = {}
      state.contact = {}
    },

    setDecodedJwt(state, decodedJwt) {
      state.decodedJwt = decodedJwt
    },

    setError(state, err) {
      state.error = err
    },
    setDebugMode(state, value) {
      state.inDebugMode = value
    },
    setTemporaryShowNewLoginRequired(state) {
      state.temporaryShowNewLoginRequired = true
    },

    loading(state, value) {
      if (value == true) {
        state.loading++
      }
      if (value == false && state.loading > 0) {
        state.loading--
      }
    }
  },
  actions: {
    async login({ commit }, { username, password }) {
      commit('loading', true)
      try {
        let decodedJwt = await authService.login(username, password)
        commit('setDecodedJwt', decodedJwt)
      } finally {
        commit('loading', false)
      }
    },
    async logout({ commit }) {
      await authService.logout()
      commit('setDecodedJwt', null)
    },

    addInfo({ commit, dispatch }, { key, value }) {
      commit('setAdditionalInfo', { key: key, value: value })
      dispatch('syncToServer', assessmentService.STATE_IN_PROGRESS)
    },

    setContact({ commit, dispatch }, contact) {
      console.log('setting contact', contact)
      commit('setContact', contact)
      dispatch('syncToServer', assessmentService.STATE_IN_PROGRESS)
    },

    async finalize({ commit, dispatch, state }) {
      if (state.assID != '') {
        await dispatch('syncToServer', assessmentService.STATE_NEW)
        slackService.sendAssessmentFinalizedNotification(this.getters.user, state.assID)
        commit('resetState')
      }
    },

    syncToServer({ state }, assState) {
      console.log('syncing to db')
      assessmentService.syncToServer(
        state.assID,
        state.answers,
        state.contact,
        assState
      )
    }
  },
  getters: {
    user: state => (state.decodedJwt ? state.decodedJwt.sub : ''),
    isAuthenticated: state => state.decodedJwt,
    isAdmin: state =>
      state.decodedJwt && state.decodedJwt.claims.includes('admin'),

    assID: state => state.assID,
    answers: state => state.answers,

    isLoading: state => state.loading > 0,

    inDebugMode: state => state.inDebugMode,
    isNewLoginServiceToken: state =>
      state.decodedJwt ? state.decodedJwt.iat : false,
    temporaryShowNewLoginRequired: state => state.temporaryShowNewLoginRequired
  }
})
