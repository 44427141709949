export const LOCALE_STORAGE_KEY = "[truckoo::locale]";

export const DE_DE = {
  id: "de-DE",
  displayString: "de",
  flag: "de.jpg"
};

export const AR_EG = {
  id: "ar-EG",
  displayString: "ar",
  flag: "ar.png"
};

export const EN_GB = {
  id: "en-GB",
  displayString: "en",
  flag: "gb.jpg"
};

export const FR_FR = {
  id: "fr-FR",
  displayString: "fr",
  flag: "fr.jpg"
};

export const CS_CZ = {
  id: "cs-CZ",
  displayString: "cz",
  flag: "cz.jpg"
};

export const IT_IT = {
  id: "it-IT",
  displayString: "it",
  flag: "it.jpg"
};

export const PL_PL = {
  id: "pl-PL",
  displayString: "pl",
  flag: "pl.jpg"
};

export const BS_BA = {
  id: "bs-BA",
  displayString: "bs",
  flag: "bs.jpg"
};

export const RU_RU = {
  id: "ru-RU",
  displayString: "русский",
  flag: "ru.jpg"
};

export const ES_ES = {
  id: "es-ES",
  displayString: "es",
  flag: "es.jpg"
};

export const RO_RO = {
  id: "ro-RO",
  displayString: "ro",
  flag: "ro.jpg"
};

export const BG_BG = {
  id: "bg-BG",
  displayString: "bg",
  flag: "bg.jpg"
};

export const ALLOWED_LANGUAGES = [
  DE_DE,
  //AR_EG,
  EN_GB,
  //FR_FR,
  //CS_CZ,
  IT_IT
  //BG_BG,
  //PL_PL,
  //BS_BA,
  //RU_RU,
  //ES_ES,
  //RO_RO
];

export function determineLanguageId() {
  let localeFromStorage = localStorage.getItem(LOCALE_STORAGE_KEY);
  let availableLocale = determineLanguage(localeFromStorage);
  return availableLocale.id ?? DE_DE.id;
}

export function determineLanguage(locale) {
  if (!locale) locale = navigator.language;
  let found = ALLOWED_LANGUAGES.find(l => l.id == locale);
  if (found) {
    console.dir("requested language found: " + found);
    return found;
  }

  console.dir("requested language not found: " + locale);

  if (locale) {
    let splitLocale = locale.split("-");
    found = ALLOWED_LANGUAGES.find(l => {
      return l.id.startsWith(splitLocale[0]);
    });
    if (found) {
      console.dir("Setting similar language: " + found.id);
      return found;
    }
  }
  console.dir("didn't find a suitable language, defaulting");
  return DE_DE;
}
