export const session = {
  name: "Session",
  methods: {},
  computed: {
    $isAdmin() {
      return this.$store.getters.isAdmin;
    },
    $username() {
      return this.$store.getters.user;
    }
  }
};
