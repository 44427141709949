import slackService from '@/services/slack-service.js'

export const logger = {
  name: 'Logger',
  methods: {
    $log(stage, message) {
      if (stage == 'debug') {
        if (this.$store.getters.inDebugMode) {
          this.$logDebug(message)
        }
      }
    },
    $logDebug(message) {
      let instance = process.env.VUE_APP_INSTANCE
      message = '*<=== ' + instance + ' ===>*\n' + message
      slackService.sendDebugInfo(message)
    }
  }
}
