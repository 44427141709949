import httpClient from '@/services/http.js'

const backendBaseUrl =
  process.env.VUE_APP_BACKEND_URL + process.env.VUE_APP_REST_VERSION
const backendUrl = backendBaseUrl + '/assessments'

class AssessmentService {
  constructor() {
    this.STATE_IN_PROGRESS = 'in-progress'
    this.STATE_NEW = 'new'
  }

  getUnfinishedAssessment() {
    return httpClient
      .get(backendUrl + '/get-unfinished-assessment')
      .then(res => res.data)
  }

  syncToServer(assID, answers, contact, state) {
    const ass = {
      assID: assID,
      input: answers,
      contact: contact,
      state: state
    }
    httpClient.put(backendUrl + '/update', ass)
  }
}

export default new AssessmentService()
